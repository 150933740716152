<template>
  <v-container fluid>
    <v-snackbar :value="isError" color="error">
      {{ isError ? getError.errorMessage : '' }}
      <v-btn color="white" text @click="setError({ isError: false, error: null})">Cerrar</v-btn>
    </v-snackbar>
    <v-snackbar :value="infoSnackbar" color="success">
      {{ infoSnackbar ? infoSnackbarMessage : '' }}
      <v-btn color="white" text @click="infoSnackbar = false">Cerrar</v-btn>
    </v-snackbar>
    <v-dialog v-model="showNuevaTiendaDialog" width="500px">
      <v-card>
        <v-card-title>Crear Nueva Tienda</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pb-0">
          <v-layout>
            <v-row>
              <v-col cols="12" sm="12">
                <v-text-field
                  outlined
                  dense
                  label="Nombre de la Tienda"
                  v-model="tiendaModel.nombreEstablecimiento"
                ></v-text-field>
                <v-text-field
                  outlined
                  dense
                  label="Dirección de la Tienda"
                  v-model="tiendaModel.direccionEstablecimiento"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-layout>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn text @click="showNuevaTiendaDialog = false">Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="green darken-1"
            :loading="isLoading"
            :disabled="isLoading"
            @click="crearTienda"
          >Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showEditTiendaDialog" width="500px">
      <v-card>
        <v-card-title>Editar Tienda</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pb-0">
          <v-layout>
            <v-row>
              <v-col cols="12" sm="12">
                <v-text-field
                  outlined
                  dense
                  :disabled="tiendaModel.nombreEstablecimiento == 'Almacén'"
                  label="Nombre de la Tienda"
                  v-model="tiendaModel.nombreEstablecimiento"
                ></v-text-field>
                <v-text-field
                  outlined
                  dense
                  label="Dirección de la Tienda"
                  v-model="tiendaModel.direccionEstablecimiento"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-layout>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn text @click="showEditTiendaDialog = false">Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="green darken-1"
            :loading="isLoading"
            :disabled="isLoading"
            @click="editarTienda"
          >Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showMovimientoProductoDialog" width="500px">
      <v-card>
        <v-card-title>Movimiento de Cantidad de Producto</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pb-0">
          <v-layout>
            <v-row>
              <v-col cols="12" sm="12" md="6" class="pb-0">
                <v-text-field
                  outlined
                  dense
                  readonly
                  label="Nombre del Producto"
                  v-model="productoSeleccionadoMovimiento.producto.nombreProducto"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6" class="pb-0">
                <v-text-field
                  outlined
                  dense
                  readonly
                  label="Stock de Producto"
                  :value="valorMovimientoProducto()"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6" class="py-0">
                <v-select
                  :items="['Entrada', 'Salida']"
                  outlined
                  dense
                  v-model="productoSeleccionadoMovimiento.tipoMovimiento"
                  label="Tipo de Movimiento"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="12" md="6" class="py-0">
                <v-text-field
                  outlined
                  dense
                  label="Cantidad a Mover"
                  hint="Tiene que ser mayor a 0"
                  v-model="productoSeleccionadoMovimiento.cantidad"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-layout>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn text @click="showMovimientoProductoDialog = false">Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="green darken-1"
            :loading="isLoading"
            :disabled="isLoading"
            @click="asignarMovimientoProducto"
          >Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="agregarProductoDialog" width="550px">
      <v-card>
        <v-card-title>Asignar Producto</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pb-0">
          <v-layout>
            <v-row>
              <v-col cols="12" sm="12">
                <v-select
                  :items="listaProductosHabilitados"
                  item-text="nombreProducto"
                  item-value="idProducto"
                  outlined
                  dense
                  label="Producto a Asignar"
                  v-model="nuevoProductoAsignadoModel.idProducto"
                ></v-select>
                <v-text-field
                  outlined
                  dense
                  :disabled="tiendaSeleccionada == 1"
                  hint="Tiene que ser mayor o igual a 0"
                  label="Cantidad Inicial"
                  v-model="nuevoProductoAsignadoModel.cantidad"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-layout>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn text @click="agregarProductoDialog = false">Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="green darken-1"
            :loading="isLoading"
            :disabled="isLoading"
            @click="asignarProductoTienda"
          >Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col>
        <v-expansion-panels v-model="panelAlmacen">
          <v-expansion-panel>
            <v-expansion-panel-header class="font-weight-bold">Información del Almacén</v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="d-flex justify-end">
                <span>
                  <v-btn
                    small
                    color="success"
                    class="mx-2"
                    @click="requestAlmacenData"
                    :disabled="isLoading"
                  >
                    Actualizar
                    <v-icon right>mdi-reload</v-icon>
                  </v-btn>
                </span>
              </div>
              <div v-if="Object.keys(detalleAlmacen).length && !isLoading">
                <div class="d-flex">
                  <v-layout>
                    <v-row>
                      <v-col cols="12" sm="12" md="7" class="pt-0">
                        <v-subheader>Lista de Productos</v-subheader>
                        <v-data-table
                          dense
                          :items="detalleAlmacen.productos"
                          :headers="headersListaProductos"
                          item-key="idProducto"
                          hide-default-footer
                        >
                          <template v-slot:item.costoBase="{ value }">S/ {{value.toFixed(2)}}</template>
                          <template v-slot:item.actions="{ item }">
                            <v-btn text icon color="blue" @click="movimientoProducto(item)">
                              <v-icon>mdi-home-export-outline</v-icon>
                            </v-btn>
                            <v-btn text icon color="red" v-if="item.idProductoVacio != null">
                              <v-icon>mdi-cart-remove</v-icon>
                            </v-btn>
                          </template>
                        </v-data-table>
                      </v-col>
                      <v-col cols="12" sm="12" md="5" class="pt-0">
                        <v-subheader>Lista de Encargados</v-subheader>
                        <v-simple-table dense>
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th class="text-left">ID</th>
                                <th class="text-left">Nombre</th>
                                <th class="text-left">Contacto</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(itemEncargado, index) in detalleAlmacen.trabajadores"
                                :key="`${itemEncargado.idTrabajador}-${index}`"
                              >
                                <td>{{ itemEncargado.idTrabajador }}</td>
                                <td>{{ itemEncargado.nombre }} {{ itemEncargado.apellidos }}</td>
                                <td>{{ itemEncargado.contacto }}</td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                        <v-subheader class="pt-4">Totalizado de Productos</v-subheader>
                        <v-simple-table dense>
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th class="text-left">Producto</th>
                                <th class="text-center">Stock Total</th>
                                <th class="text-center">Stock Disponible Total</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(itemTotalizado, index) in detalleAlmacen.totalizado"
                                :key="`${itemTotalizado.idProducto}-${index}`"
                              >
                                <td>{{itemTotalizado.nombreProducto}}</td>
                                <td class="text-center">{{itemTotalizado.stockTotal}}</td>
                                <td class="text-center">{{itemTotalizado.stockDisponibleTotal}}</td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-col>
                      <v-col cols="12" sm="12" class="d-flex justify-end pt-0">
                        <v-btn outlined color="teal" @click="abrirDialogProductoTienda(1)">
                          <v-icon left>mdi-cart-plus</v-icon>Agregar Producto
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-layout>
                </div>
                <div class="d-flex"></div>
              </div>
              <div class="d-flex" v-else>
                <v-skeleton-loader
                  ref="expSkeleton"
                  type="article"
                  class="px-0"
                  style="width: 100%"
                ></v-skeleton-loader>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-data-table
          fixed-header
          :headers="headers"
          :items="getListaTiendas"
          :loading="isLoading"
          loading-text="Cargando... Espera un Momento"
          no-data-text="No hay datos para mostrar"
          :search="buscarTabla"
          item-key="idEstablecimiento"
          class="elevation-1"
          single-expand
          single-select
          show-expand
          @item-expanded="whenExpanded"
        >
          <template v-slot:top>
            <v-toolbar flat color="white">
              <div>
                <v-toolbar-title>TIENDAS</v-toolbar-title>
              </div>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-btn icon color="success" class="mx-2" @click="actualizarTabla">
                <v-icon>mdi-reload</v-icon>
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="success" dark @click="abrirCrearTiendaDialog">
                Agregar
                <v-icon right>mdi-plus-circle-outline</v-icon>
              </v-btn>
            </v-toolbar>
            <v-layout class="py-3 d-flex">
              <v-row>
                <v-col cols="12" sm="12" md="8">
                  <v-text-field
                    v-model="buscarTabla"
                    prepend-inner-icon="mdi-magnify"
                    label="Buscar en la tabla..."
                    class="mx-4 flex-shrink-0 flex-grow-1"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-layout>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn icon color="teal" @click="abrirEditTiendaDialog(item)">
              <v-icon>mdi-square-edit-outline</v-icon>
            </v-btn>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <div v-if="!isLoading">
                <div class="d-flex">
                  <v-layout>
                    <v-row>
                      <v-col cols="12" sm="12" md="8" class="pt-0">
                        <v-subheader>Lista de Productos</v-subheader>
                        <v-data-table
                          dense
                          :items="detalleTienda.productos"
                          :headers="headersListaProductos"
                          item-key="idProducto"
                          hide-default-footer
                        >
                          <template v-slot:item.costoBase="{ value }">S/ {{value.toFixed(2)}}</template>
                          <template v-slot:item.actions="{ item }">
                            <v-btn text icon color="blue" @click="movimientoProducto(item)">
                              <v-icon>mdi-home-export-outline</v-icon>
                            </v-btn>
                            <v-btn text icon color="red" v-if="item.idProductoVacio != null">
                              <v-icon>mdi-cart-remove</v-icon>
                            </v-btn>
                          </template>
                        </v-data-table>
                      </v-col>
                      <v-col cols="12" sm="12" md="4" class="pt-0">
                        <v-subheader>Lista de Encargados</v-subheader>
                        <v-simple-table dense>
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th class="text-left">ID</th>
                                <th class="text-left">Nombre</th>
                                <th class="text-left">Contacto</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(itemEncargado, index) in detalleTienda.trabajadores"
                                :key="`${itemEncargado.idTrabajador}-${index}`"
                              >
                                <td>{{ itemEncargado.idTrabajador }}</td>
                                <td>{{ itemEncargado.nombre }} {{ itemEncargado.apellidos }}</td>
                                <td>{{ itemEncargado.contacto }}</td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-col>
                      <v-col cols="12" sm="12" class="d-flex justify-end pt-0">
                        <v-btn
                          outlined
                          color="teal"
                          :disabled="!listaProductosHabilitados.length"
                          @click="abrirDialogProductoTienda(item.idEstablecimiento)"
                        >
                          <v-icon left>mdi-cart-plus</v-icon>Agregar Producto
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-layout>
                </div>
                <div class="d-flex"></div>
              </div>
              <div class="d-flex" v-else>
                <v-skeleton-loader
                  ref="expSkeleton"
                  type="article"
                  class="px-0"
                  style="width: 100%"
                ></v-skeleton-loader>
              </div>
            </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  data: () => ({
    buscarTabla: '',
    trabajadores: [],
    headers: [
      { text: 'ID', value: 'idEstablecimiento', align: 'center' },
      { text: 'Cliente', value: 'nombreEstablecimiento', align: 'center' },
      { text: 'Dirección', value: 'direccionEstablecimiento', align: 'center' },
      { text: 'Activo', value: 'activo', align: 'center' },
      { text: 'Acciones', value: 'actions', align: 'center' },
      { text: '', value: 'data-table-expand' }
    ],
    infoSnackbar: false,
    showNuevaTiendaDialog: false,
    showEditTiendaDialog: false,
    panelAlmacen: 0,
    tiendaSeleccionada: 0,
    nuevaTiendaEmpty: {
      nombreEstablecimiento: '',
      direccionEstablecimiento: ''
    },
    tiendaModel: {
      nombreEstablecimiento: '',
      direccionEstablecimiento: ''
    },
    detalleAlmacen: {},
    detalleTienda: {},
    showMovimientoProductoDialog: false,
    productoSeleccionadoMovimiento: {
      tipoMovimiento: '',
      producto: {},
      cantidad: 0
    },
    headersListaProductos: [
      { text: 'Producto', value: 'nombreProducto', align: 'center' },
      { text: 'Stock Total', value: 'stockProducto', align: 'center' },
      { text: 'Stock Disponible', value: 'stockDisponible', align: 'center' },
      { text: 'Costo Base', value: 'costoBase', align: 'center' },
      { text: 'Acciones', value: 'actions', align: 'left', sortable: false }
    ],
    agregarProductoDialog: false,
    listaProductosHabilitados: [],
    nuevoProductoAsignadoModel: {
      idProducto: 0,
      cantidad: 0
    },
    rowSeleccionado: {}
  }),

  methods: {
    formatDateTable(date) {
      let parsedDate = new Date(parseInt(date))
      let options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      }
      return `${parsedDate.toLocaleDateString(
        'es-PE',
        options
      )} ${parsedDate.toLocaleTimeString()}`
    },

    cleanSource(source) {
      return JSON.parse(JSON.stringify(source))
    },

    async actualizarTabla() {
      await this.fetchListaTiendas()
    },

    async getAlmacenData() {
      let almacenData = this.getListaTiendas.filter(
        item => item.nombreEstablecimiento.indexOf('Almacén') >= 0
      )
      this.detalleAlmacen = await this.fetchDetalleTienda(
        almacenData[0] ? almacenData[0].idEstablecimiento : 1
      )
    },

    async crearTienda() {
      let response = await this.postNuevaTienda(this.tiendaModel)
      if (response) {
        this.showNuevaTiendaDialog = false
        this.tiendaModel = this.nuevaTiendaEmpty
        await this.actualizarTabla()
      }
    },

    abrirCrearTiendaDialog() {
      this.tiendaModel = this.nuevaTiendaEmpty
      this.showNuevaTiendaDialog = true
    },

    async editarTienda() {
      let response = await this.putEditarTienda({
        idEstablecimiento: this.tiendaSeleccionada,
        tienda: this.tiendaModel
      })
      if (response) {
        this.showEditTiendaDialog = false
        this.tiendaModel = this.nuevaTiendaEmpty
        await this.actualizarTabla()
      }
    },

    abrirEditTiendaDialog(item) {
      this.tiendaSeleccionada = item.idEstablecimiento
      if (item.idEstablecimiento == 1) {
        this.setListaProductosAlmacen()
      }
      this.tiendaModel.nombreEstablecimiento = item.nombreEstablecimiento
      this.tiendaModel.direccionEstablecimiento = item.direccionEstablecimiento
      this.showEditTiendaDialog = true
    },

    async whenExpanded(row) {
      if (row.value) {
        this.rowSeleccionado = row
        this.expandedLoading = true
        this.tiendaSeleccionada = row.item.idEstablecimiento
        this.detalleTienda = await this.fetchDetalleTienda(
          this.tiendaSeleccionada
        )

        let [...prodFiltrados] = this.getListaProductosFiltrada
        let [...response] = this.detalleTienda.productos

        this.listaProductosHabilitados = prodFiltrados.filter(function(item) {
          let { ...auxItem } = item
          return !response.some(i => auxItem.idProducto == i.idProducto)
        })

        this.expandedLoading = false
      } else {
        this.rowSeleccionado = {}
      }
    },

    setListaProductosAlmacen() {
      let [...prodFiltrados] = this.getListaProductosFiltrada
      let [...response] = this.detalleAlmacen.productos

      this.listaProductosHabilitados = prodFiltrados.filter(function(item) {
        let { ...auxItem } = item
        return !response.some(i => auxItem.idProducto == i.idProducto)
      })
    },

    movimientoProducto(item) {
      this.productoSeleccionadoMovimiento = {
        tipoMovimiento: '',
        producto: {},
        cantidad: 0
      }
      this.showMovimientoProductoDialog = true
      let { ...itemSeleccionado } = item
      this.productoSeleccionadoMovimiento.producto = itemSeleccionado
    },

    valorMovimientoProducto() {
      let response = 0
      switch (this.productoSeleccionadoMovimiento.tipoMovimiento) {
        case 'Entrada':
          response =
            parseInt(this.productoSeleccionadoMovimiento.cantidad) +
            this.productoSeleccionadoMovimiento.producto.stockDisponible
          if (isNaN(response))
            response = this.productoSeleccionadoMovimiento.producto
              .stockDisponible
          break
        case 'Salida':
          response =
            this.productoSeleccionadoMovimiento.producto.stockDisponible -
            this.productoSeleccionadoMovimiento.cantidad
          break
        default:
          response = this.productoSeleccionadoMovimiento.producto
            .stockDisponible
          break
      }
      return response
    },

    async asignarMovimientoProducto() {
      let response = await this.postMovimientoProductoTienda(
        this.productoSeleccionadoMovimiento
      )
      if (response) {
        this.showMovimientoProductoDialog = false
        this.tiendaModel = this.nuevaTiendaEmpty
        await this.getAlmacenData()
        if (this.tiendaSeleccionada != 0) {
          this.detalleTienda = await this.fetchDetalleTienda(
            this.tiendaSeleccionada
          )
        }
        await this.actualizarTabla()
      }
    },

    abrirDialogProductoTienda(idEstablecimento) {
      this.tiendaSeleccionada = idEstablecimento
      this.nuevoProductoAsignadoModel = {
        idProducto: 0,
        cantidad: 0
      }
      this.agregarProductoDialog = true
    },

    async asignarProductoTienda() {
      let response = await this.postAsignarNuevoProductoTienda({
        idEstablecimiento: this.tiendaSeleccionada,
        producto: this.nuevoProductoAsignadoModel
      })
      if (response) {
        this.agregarProductoDialog = false
        this.nuevoProductoAsignadoModel = {
          idProducto: 0,
          cantidad: 0
        }
        await this.getAlmacenData()
        await this.whenExpanded(this.rowSeleccionado)
        await this.actualizarTabla()
      }
    },

    async requestAlmacenData() {
      await this.getAlmacenData()
      this.setListaProductosAlmacen()
    },

    ...mapActions('tienda', [
      'fetchListaTiendas',
      'postNuevaTienda',
      'fetchDetalleTienda',
      'putEditarTienda',
      'postMovimientoProductoTienda',
      'postAsignarNuevoProductoTienda'
    ])
  },

  computed: {
    ...mapGetters('user', [
      'isLoading',
      'isError',
      'getError',
      'getListaProductosFiltrada'
    ]),
    ...mapGetters('tienda', ['getListaTiendas'])
  },

  mounted: async function() {
    await this.requestAlmacenData()
  }
}
</script>

<style>
</style>